<template>
  <b-card
    no-body
  >
    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :fields="fields"
      :items="items"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @sort-changed="sortManual"
    >
      <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>

      <template #cell(createdAt)="data">
          {{data.item.createdAt_cast}}
      </template>

      <template #cell(name)="data">
          {{data.item.campaign.name}}
      </template>

      <template #cell(paymentAmount)="data">
          {{data.item.paymentAmount_cast}}
      </template>

      <template #cell(amount)="data">
          {{data.item.amount}}
      </template>

      <template #cell(status)="data">
          <p class="text-capitalize">{{data.item.status}}</p>
      </template>

      <template #cell(file)="data">
        <b-link @click="DowmloadFile(data.item.advertiserAttachment)" v-if="data.item.advertiserAttachment ? data.item.advertiserAttachment.split('/')[4] : '-'">
          <span style="color:blue;"> {{ data.item.advertiserAttachment ? data.item.advertiserAttachment.split('/')[4] : '-' }} </span>
        </b-link>
      </template>


      <template #cell(action)="data">
        <div style="min-width:200px;">
          <b-button
              v-show="data.item.attachment && data.item.status == 'reviewed' || data.item.status == 'waiting-for-approval'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="downloadAttachemnt(data)"
              >
              <feather-icon icon="DownloadIcon" />
          </b-button>
          &nbsp;
          
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              v-show="data.item.status == 'reviewed' || data.item.status == 'waiting-for-approval' "
              @click="showModal(data)"
              >
              <feather-icon icon="UploadIcon" />
          </b-button>
        </div>
      </template>
      <template #empty="scope">
        <h5 class="text-center">Tidak Ada Data</h5>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
    <b-modal ref="my-modal" @ok="submitFile" title="Upload file konfirmasi persetujuan termin">
        <div class="d-block text-center">
            <b-form-file id="file" ref="file" v-on:change="handleFileUpload()"></b-form-file>
        </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BCard, BFormFile, BLink,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { EventBus } from "@/eventBus.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile,
    BLink,
  },
  directives: {
      Ripple,
  },
  props: [
    "names",
    "filtering"
  ],
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createdAt',
      tamp:'desc',
      sortDesc: false,
      sortDirection: 'DESC',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'no', label: 'No' },
        { key: 'createdAt', label: 'Tanggal', sortable:true },
        { key: 'name', label: 'Campaign', sortable:true},
        { key: 'paymentAmount', label: 'Total Pembayaran', sortable:true },
        { key: 'amount', label: 'Termin Pembayaran', sortable:true },
        { key: 'status', label: 'Status', sortable:true },
        { key: 'file', label: 'File' },
        
        {key: 'action', label: 'Aksi', thStyle: 'width:140px'}
      ],
      /* eslint-disable global-require */
      items: [],
      terminID: null,
      file: '',
      name:'',
      dateTo:'',
      dateFrom:'',
      configs:'',
      urls:''
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    EventBus.$on("filters_termin", () => {
      console.log(this.filtering);
      this.dateFrom = this.filtering.dateFrom
      this.dateTo = this.filtering.dateTo
      this.getListTermin()
    });

    EventBus.$on("resetfFlter", () => {
      this.resetFilters();
    });
    this.getListTermin()
  },
  methods: {
    sortManual(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc == true
      this.tamp =  this.sortDesc == true ? 'DESC' : 'ASC'
      this.getListTermin()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    DowmloadFile(files)
    {
      window.open(files)
    },
    getListTermin(name="",page=1) {
      let orderBy = this.tamp
      let result = this.sortBy + "." + orderBy
      this.configs = '/advertiser/termin/filter?page=' + page + "&sortBy=" + this.sortBy + '&orderBy=' + this.tamp  + '&name=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
      this.urls = this.configs
      axios.get( this.configs)
        .then(resp => {
          let _ = this
          let termin = resp.data.data
          
          // let data = termin.data.map((v,i) => {
          //   let filename = v.advertiserAttachment ? v.advertiserAttachment.split('/')[4] : '-'
          //   let advAttachment = v.advertiserAttachment
          //   let urlFile = v.attachment
          //   return {
          //         id: v.id,
          //         // no: parseInt(i)+parseInt(1),
          //         createdAt: v.createdAt_cast,
          //         campaign_name: v.campaign.name,
          //         paymentAmount: v.paymentAmount_cast,
          //         amount: v.amount,
          //         status: v.status,
          //         file: filename,
          //         urlFile: urlFile,
          //         advAttachmenturl : advAttachment
          //     }
          // })

          _.items = termin.data
          _.totalRows = termin.totalData
          _.perPage = 10
          _.currentPage = termin.currentPage
        })
        .catch(err => {
          console.log(err.resp)
        })
    },
    showModal(data) {
      let _ = this
      _.terminID = data.item.id
      _.$refs['my-modal'].show()
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);

      axios.put( '/advertiser/termin/'+this.terminID+'/submit-attachment',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then(resp => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: resp.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.getListTermin()
      })
      .catch(function(){
        console.log('FAILURE!!');
      });
    },
    handleFileUpload(){
      console.log('handleFileUpload')
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    changePage(page) {
      console.log(page)
      this.getListTermin(this.name,page)
    },
    resetFilters()
    {
      console.log("hallo");
      this.dateFrom = '',
      this.dateTo = '',
      this.getListTermin()
    },
    downloadAttachemnt(data)
    {
      let url = data.item.attachment
      window.open(url)
    },
  },
  watch:{
    names:function (newValue) {
      let _ = this
      this.name = newValue
      _.getListTermin(this.name)
    },
  }
}
</script>
